import { render, staticRenderFns } from "./Credits.vue?vue&type=template&id=18fddcc8&scoped=true&"
import script from "./Credits.vue?vue&type=script&lang=js&"
export * from "./Credits.vue?vue&type=script&lang=js&"
import style0 from "./Credits.vue?vue&type=style&index=0&id=18fddcc8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18fddcc8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VCard,VCardText,VProgressLinear,VSheet,VTooltip})
