<template>
    <v-card flat tile>
        <SwitchCategoryDialog :show="showSwitchDialog" :keywords="selectedRows" @close="showSwitchDialog = false" @catUpdated="catUpdated"></SwitchCategoryDialog>
        <DeleteDialog :deleteDialog="deleteDialog" :selectedDeleteItem="''" :deleteLabel="deleteLabel" :loading="deleteLoading" @delete="deleteKeywords" @close="deleteDialog = false"></DeleteDialog>
        <ConfirmDialog
            :confirmDialog="confirmDialog"
            :selectedItem="''"
            :label="confirmLabel"
            :loading="confirmLoading"
            @confirm="removeKeywordsCategory"
            @close="confirmDialog = false"></ConfirmDialog>
        <v-slide-x-reverse-transition hide-on-leave>
            <div style="position: relative" v-if="selectedRows.length">
                <div style="position: absolute; z-index: 5; top: -38px; right: 16px">
                    <v-chip outlined small class="mr-2 pl-4 pr-1">
                        {{ selectedRows.length }} SELECTED
                        <v-btn class="mx-1" x-small icon @click="selectedRows = []"><v-icon x-small>mdi-close</v-icon></v-btn>
                    </v-chip>
                    <v-divider vertical></v-divider>
                    <v-tooltip top :disabled="!test_set">
                        <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                                <v-btn
                                    elevation="0"
                                    :disabled="$store.state.monitoring_suite_group.id == 2 || test_set"
                                    color="primary"
                                    outlined
                                    rounded
                                    small
                                    class="mr-2"
                                    @click="showSwitchDialog = true">
                                    <v-icon class="mr-1" small>mdi-swap-horizontal</v-icon>category</v-btn
                                >
                            </span>
                        </template>
                        <span>Disabled on Example-Set</span>
                    </v-tooltip>
                    <v-tooltip top :disabled="!test_set">
                        <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                                <v-btn
                                    v-if="selectedRows.filter((x) => x.category_id).length > 0"
                                    elevation="0"
                                    :disabled="$store.state.monitoring_suite_group.id == 2 || test_set"
                                    color="warning"
                                    outlined
                                    rounded
                                    small
                                    class="mr-2"
                                    @click="confirmDialog = true">
                                    <v-icon class="mr-1" small>mdi-tag-remove</v-icon>category</v-btn
                                >
                            </span>
                        </template>
                        <span>Disabled on Example-Set</span>
                    </v-tooltip>
                    <v-tooltip top :disabled="!test_set">
                        <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                                <v-btn elevation="0" :disabled="$store.state.monitoring_suite_group.id == 2 || test_set" outlined rounded color="error" small @click="deleteDialog = true">
                                    <v-icon class="mr-1" small>mdi-delete</v-icon> delete</v-btn
                                >
                            </span>
                        </template>
                        <span>Disabled on Example-Set</span>
                    </v-tooltip>
                </div>
            </div>
        </v-slide-x-reverse-transition>
        <v-slide-x-reverse-transition hide-on-leave>
            <div style="position: relative" v-if="!selectedRows.length">
                <div style="position: absolute; z-index: 5; top: -38px; right: 16px">
                    <v-tooltip top :disabled="!test_set">
                        <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                                <v-btn :disabled="$store.state.monitoring_suite_group.id == 2 || test_set" elevation="0" color="primary" outlined rounded small @click="$emit('showAddKwDialog')">
                                    <v-icon class="mr-1" small>mdi-cloud-upload</v-icon>keywords</v-btn
                                >
                            </span>
                        </template>
                        <span>Disabled on Example-Set</span>
                    </v-tooltip>
                </div>
                <div style="position: absolute; z-index: 5; top: -38px; right: 150px">
                    <v-tooltip top :disabled="!test_set">
                        <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                                <v-btn :disabled="$store.state.monitoring_suite_group.id == 2 || test_set" elevation="0" color="primary" outlined rounded small @click="showCategorySwitchDialog">
                                    <v-icon class="mr-1" small>mdi-swap-horizontal</v-icon>category</v-btn
                                >
                            </span>
                        </template>
                        <span>Disabled on Example-Set</span>
                    </v-tooltip>
                </div>
                <div style="position: absolute; z-index: 5; top: -38px; right: 275px">
                    <filter-menu :fields="filterFields" @filtersUpdated="filtersUpdated" :dense="true"></filter-menu>
                </div>
            </div>
        </v-slide-x-reverse-transition>

        <v-data-table
            :headers="headers"
            :items="rankings"
            :loading="loading"
            :sort-desc.sync="sortDesc"
            :sort-by.sync="sortBy"
            :page.sync="page"
            :search.sync="search"
            :items-per-page.sync="itemsPerPage"
            :server-items-length="count"
            :multi-sort="false"
            :footer-props="footerProps"
            @update:options="fetchData"
            fixed-header
            v-model="selectedRows"
            @click:row="onRowClick"
            item-key="_id"
            height="70vh"
            id="rankingstable"
            class="elevation-0">
            <!-- header slot -->
            <template v-for="(header_entry, index) in headers" v-slot:[`header.${header_entry.value}`]="{ header }">
                <span :key="index">
                    <span v-bind:style="{ width: header.width }">
                        <!-- Adding tooltips to header -->
                        <v-tooltip max-width="250" top :disabled="header.tooltip ? false : true">
                            <template v-slot:activator="{ on }">
                                <span v-on="on" style="white-space: nowrap; text-transform: uppercase; font-weight: 500">
                                    {{ header.text }}
                                    <v-icon class="questionIcon" v-if="header.tooltip" x-small>mdi-help-circle-outline</v-icon>
                                </span>
                            </template>
                            <span>
                                <v-row no-gutters class="mb-2">
                                    <div cols="12"><v-icon color="white">mdi-help-circle-outline</v-icon></div>
                                </v-row>

                                <div v-if="header.value.substring(0, 8) == 'ranking_'">
                                    <div class="mt-2">
                                        <span
                                            ><b> Google Ranking for {{ header.text }}:</b>
                                        </span>
                                    </div>
                                    {{ header.tooltip }}
                                    <div class="mt-2">
                                        <span><b> Colorcodes:</b> </span>
                                    </div>
                                    <span v-for="(color, index) in positionColors2" :key="index">
                                        <v-chip x-small class="mr-1 mb-1 mt-1" :color="color">{{ (index == 0 ? index : index * 10) + " - " + (index + 1) * 10 }}</v-chip>
                                    </span>
                                </div>
                                <div v-if="header.value == 'keyword_intent'">
                                    <div class="mt-2">
                                        <span><b> Search Intent:</b> </span>
                                    </div>
                                    {{ header.tooltip }}
                                    <div class="mt-2">
                                        <span><b> Intentions:</b> </span>
                                    </div>
                                    <span v-for="(icon, intent) in intent_icons" :key="intent">
                                        <v-chip class="mr-1 mb-1 mt-1" color="primary">
                                            <v-icon size="16px" class="mr-1">{{ icon }}</v-icon> {{ intent }}
                                        </v-chip>
                                        <div>
                                            <p>{{ intentTooltips[intent] }}</p>
                                        </div>
                                    </span>
                                </div>

                                <div v-else>{{ header.tooltip }}</div>
                            </span>
                        </v-tooltip>
                    </span>
                </span>
            </template>
            <template v-slot:item.keyword="{ item }">
                <div :class="!item.blur ? 'py-2' : 'py-2 blur'" style="padding-left: -5px !important">
                    <span class="rtd pl-2">{{ !item.blur ? item.keyword : item.keyword.slice(0, Math.ceil(item.keyword.length / 3)) }}</span> <br />
                    <div style="white-space: nowrap" v-if="item.category_id">
                        <v-btn icon x-small @click="showAllCatsInTable"
                            ><v-icon x-small>{{ showAllCategories ? "mdi-chevron-right" : "mdi-chevron-left" }}</v-icon></v-btn
                        >
                        <span v-if="item.category_id && showAllCategories" style="white-space: nowrap">
                            <span v-for="(cat, index) in item.category_id.split(';')" :key="cat">
                                <v-icon v-if="index" x-small class="px-0">mdi-chevron-right</v-icon>
                                <v-chip x-small @click="!item.blur ? setCat(item, index) : null"> {{ cat }}</v-chip>
                            </span>
                        </span>
                        <span v-else>
                            <v-chip x-small @click="!item.blur ? setCategory(item) : null">
                                {{ !item.blur ? item.category_id.split(";")[item.category_id.split(";").length - 1] : item.category_id.slice(0, Math.ceil(item.category_id.length / 3)) }}</v-chip
                            >
                        </span>
                    </div>
                </div>
            </template>
            <template v-slot:item.avg_search_volume="{ item }">
                <v-chip outlined class="qchip" color="primary" style="">
                    {{ $helpers.formatNumber(item.avg_search_volume) }}
                </v-chip>
            </template>
            <template v-slot:item.keyword_intent="{ item }">
                <div style="display: inline-flex" v-if="item.sorted_search_intent">
                    <!-- Haupt-Intent -->
                    <span class="pa-1 d-flex">
                        <v-tooltip max-width="250" color="primary" top>
                            <template v-slot:activator="{ on }">
                                <v-progress-linear
                                    v-on="on"
                                    style="border-radius: 16px; font-size: 14px; width: 70px; margin: auto; opacity: 0.8"
                                    class="white--text intent"
                                    rounded
                                    :value="item.sorted_search_intent[0][1]"
                                    color="primary"
                                    height="32">
                                    <v-icon class="mr-1" size="16px" color="white">{{ intent_icons[item.sorted_search_intent[0][0]] }}</v-icon>
                                    <!-- Name des ersten Intents -->
                                    {{ Math.ceil(item.sorted_search_intent[0][1]) + "%" }}
                                    <!-- Prozentwert des ersten Intents -->
                                </v-progress-linear>
                            </template>
                            <span> {{ item.sorted_search_intent[0][0] }}</span>
                        </v-tooltip>
                    </span>
                    <v-slide-x-transition>
                        <span v-if="show_secondary_intents" style="display: inline-flex">
                            <span v-for="(intent, index) in item.sorted_search_intent.slice(1)" :key="index" class="pa-1">
                                <!-- Alle bis auf den ersten Intent -->
                                <v-tooltip max-width="250" color="primary" top>
                                    <template v-slot:activator="{ on }">
                                        <v-progress-linear
                                            v-on="on"
                                            style="border-radius: 15px; font-size: 14px; width: 70px; margin: auto; opacity: 0.8"
                                            class="white--text intent"
                                            rounded
                                            :value="intent[1]"
                                            color="primary"
                                            height="32">
                                            <v-icon class="mr-1" size="16px" color="white">{{ intent_icons[intent[0]] }}</v-icon>
                                            <!-- Name des sekundären Intents -->
                                            {{ Math.ceil(intent[1]) + "%" }}
                                            <!-- Prozentwert des sekundären Intents -->
                                        </v-progress-linear>
                                    </template>
                                    <span> {{ intent[0] }}</span>
                                </v-tooltip>
                            </span>
                        </span>
                    </v-slide-x-transition>
                    <v-btn
                        @click="
                            (e) => {
                                e.stopPropagation()
                                show_secondary_intents = !show_secondary_intents
                            }
                        "
                        icon
                        class="ma-auto">
                        <v-icon>{{ show_secondary_intents ? "mdi-chevron-left" : "mdi-chevron-right" }}</v-icon>
                    </v-btn>
                </div>
            </template>

            <template v-for="competitor in selectedCompetitors" v-slot:[`item.ranking_${competitor.domain}`]="{ item }">
                <v-dialog
                    id="rankingmenu"
                    style="border-radius: 10px !important"
                    offset-y
                    v-model="menuOpenState[competitor.domain + item.keyword]"
                    rounded
                    @click:outside="menuOpenState[competitor.domain + item.keyword] = false"
                    :close-on-content-click="false"
                    @input="menuOpen(item, competitor.domain)"
                    :key="competitor.domain">
                    <template v-slot:activator="{ on }">
                        <!-- Hier wird der Aktivatorinhalt platziert, z.B. ein Button -->
                        <span v-on="on">
                            <div class="pr-3" v-bind="(posData = getRankingPosition(item, competitor.domain))">
                                <v-row class="compranking-wrapper">
                                    <v-chip class="compranking-wrapper" outlined :color="getColor(posData.position)" :class="posData.change == null ? 'qchip ml-auto' : ' ml-auto qchip'">
                                        {{ posData.position }}
                                    </v-chip>
                                    <div class="d-flex my-auto ml-1 px-2" v-if="posData.change != null" style="border: 1px solid rgb(187, 187, 187); border-radius: 14px">
                                        <v-icon :color="posData.change ? (posData.change < 0 ? 'red' : 'green') : ''" small class="my-auto">{{
                                            posData.change ? (posData.change < 0 ? "mdi-chevron-down" : "mdi-chevron-up") : "mdi-chevron-right"
                                        }}</v-icon>
                                        <span :style="posData.change ? (posData.change < 0 ? 'color: red' : 'color: green') : ''" class="rankingchange my-auto">{{ posData.change }}</span>
                                    </div>
                                </v-row>
                            </div>
                        </span>
                    </template>
                    <v-list style="color: rgb(255, 255, 255); border-radius: 10px !important">
                        <!-- Ihr bisheriger Tooltip-Inhalt geht hier hinein -->
                        <div class="px-3 py-1" v-if="tooltipMenuData.datasets && tooltipMenuData.datasets.length">
                            <div class="d-flex my-4">
                                <div style="display: block !important" class="px-2">
                                    <div class="mr-1 my-auto quarolbl mb-1" style="font-size: 16px">Position Changes: {{ tooltipMenuData.keyword }}</div>
                                    <div class="my-auto">
                                        <v-chip small class="mr-1">
                                            <img class="ma-auto mr-1" :src="'https://s2.googleusercontent.com/s2/favicons?domain=' + tooltipMenuData.domain + '&sz=42'" alt="" />
                                            {{ tooltipMenuData.domain }}
                                        </v-chip>
                                        <v-chip small>
                                            <v-icon class="mr-1" small>mdi-clock-time-eight-outline</v-icon>
                                            <span v-if="tooltipMenuData.labels[1] != null">
                                                {{ tooltipMenuData.labels[0].substring(0, 10) + " - " + tooltipMenuData.labels[tooltipMenuData.labels.length - 1].substring(0, 10) }}
                                            </span>
                                            <span v-else>
                                                {{ tooltipMenuData.labels[0].substring(0, 10) }}
                                            </span></v-chip
                                        >
                                    </div>
                                </div>
                                <v-spacer class="mr-2"></v-spacer>

                                <v-btn class="ml-2 mr-2" style="background-color: var(--v-primary-base)" icon @click="menuOpenState[competitor.domain + item.keyword] = false">
                                    <v-icon color="white">mdi-close</v-icon></v-btn
                                >
                            </div>
                            <v-divider></v-divider>
                            <v-card-text class="pa-0">
                                <v-row v-if="tooltipMenuData.labels[1] != null">
                                    <v-col cols="12">
                                        <line-chart
                                            v-if="menuOpenState[competitor.domain + item.keyword]"
                                            :dataChart="tooltipMenuData.chartData"
                                            :countGraph2="renderChart"
                                            :options="graphOptions"></line-chart>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" class="pa-0">
                                        <v-divider></v-divider>
                                        <v-data-table id="urlRankingsTable" :headers="tooltipMenuData.tableHeaders" :items="tooltipMenuData.tableRows" hide-default-footer>
                                            <template v-for="(header_entry, index) in tooltipMenuData.tableHeaders" v-slot:[`header.${header_entry.value}`]="{ header }">
                                                <span :key="index">
                                                    <span v-bind:style="{ width: header.width }">
                                                        <!-- Adding tooltips to header -->
                                                        <span style="white-space: nowrap; text-transform: uppercase; font-weight: 500; font-size: 14px">
                                                            {{ header.text }}
                                                        </span>
                                                    </span>
                                                </span>
                                            </template>

                                            <template v-for="(header_entry, index) in tooltipMenuData.tableHeaders" v-slot:[`item.${header_entry.value}`]="{ item }">
                                                <span class="pa-1" v-if="index == 0">
                                                    <span class="d-flex pa-1">
                                                        <div
                                                            class="fav_wrapper my-auto mr-4 ml-3"
                                                            :style="'border-color:' + getColoFromIndex(tooltipMenuData.tableRows.findIndex((d) => d.url == item.url)) + ' !important;'">
                                                            <img class="ma-auto" :src="'https://s2.googleusercontent.com/s2/favicons?domain=' + item.url + '&sz=42'" alt="" />
                                                        </div>
                                                        <span class="my-auto" style="white-space: normal">{{ item.url }}</span>
                                                        <!-- <span class="my-auto">{{ item.url.length > 80 ? item.url.substring(0, 80) + "..." : item.url }}</span> -->
                                                        <v-btn class="ml-1 my-auto" @click="openURL(item.url)" icon primary> <v-icon>mdi-link</v-icon></v-btn>
                                                    </span>
                                                </span>
                                                <span class="pa-1" v-if="index > 0">
                                                    <v-chip class="compranking-wrapper qchip" style="background-color: whitesmoke !important" outlined :color="getColor(item[header_entry.value])">
                                                        {{ item[header_entry.value] || "-" }}
                                                    </v-chip>
                                                </span>
                                            </template>
                                        </v-data-table>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </div>
                        <div v-else>
                            <div class="pa-5 d-flex">
                                <v-spacer class="mr-2"></v-spacer>
                                <v-chip class="mx-auto"> no ranking data</v-chip>
                                <v-spacer class="mr-2"></v-spacer>
                                <v-btn class="ml-2 mr-2" style="background-color: var(--v-primary-base)" icon @click="menuOpenState[competitor.domain + item.keyword] = false">
                                    <v-icon color="white">mdi-close</v-icon></v-btn
                                >
                            </div>
                        </div>
                    </v-list>
                </v-dialog>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
    import SwitchCategoryDialog from "./SwitchCategoryDialog"
    import FilterMenu from "./FilterMenu"
    import DeleteDialog from "../dialogs/DeleteDialog.vue"
    import ConfirmDialog from "../dialogs/ConfirmDialog.vue"
    import { positionColors } from "../helper/global.js"
    import LineChart from "../charts/LineChart"
    export default {
        props: {
            selectedCompetitors: {
                type: Array,
                required: true
            },
            selectedDates: {
                type: Array
            },
            category_id: {
                type: String
            },
            search: {
                type: String
            },
            project_domain: {
                type: String
            },
            exportData: {
                type: Number
            },
            categorized_project: { type: Boolean }
        },
        components: { SwitchCategoryDialog, DeleteDialog, FilterMenu, LineChart, ConfirmDialog },
        data() {
            return {
                show_intents: false,
                show_secondary_intents: false,
                intentTooltips: {
                    informational: "Looking for information on a specific topic, such as “what is SEO?”",
                    navigational: "Navigating to a certain site or page, for instance, “Facebook login page”.",
                    commercial: "Conducting a commercial investigation, that is, researching products or services before making a decision on what to invest in, such as “best SEO tools”.",
                    transactional: "Making a specific purchase, for example, “buy SEO software”."
                },
                intent_icons: { informational: "mdi-information-outline", navigational: "mdi-navigation-outline", commercial: "mdi-shopping-search-outline", transactional: "mdi-shopping-outline" },
                renderChart: 0,
                deleteDialog: false,
                confirmDialog: false,
                showSwitchDialog: false,
                selectedRows: [],
                tooltipMenuData: {},
                menuOpenState: {},
                rankings: [],
                count: 0,
                loading: false,
                deleteLoading: false,
                confirmLoading: false,
                sortDesc: true,
                sortBy: "avg_search_volume",
                page: 1,
                itemsPerPage: 25,
                pagination: {},
                sortDomain: null,
                showAllCategories: false,
                filters: [],
                graphOptions: {
                    tooltips: {
                        callbacks: {
                            label: function (tooltipItems, data) {
                                return data.datasets[tooltipItems.datasetIndex].label + ": " + data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index].toLocaleString()
                            }
                        }
                    },
                    legend: {
                        display: true,
                        labels: {
                            fontSize: 14
                        }
                    },
                    plugins: {
                        legend: {
                            labels: {
                                usePointStyle: true,
                                pointStyle: "rectRounded"
                            }
                        }
                    },
                    scales: {
                        yAxes: [
                            {
                                scaleLabel: {
                                    display: true,
                                    labelString: "Google Position (organic)"
                                },
                                ticks: {
                                    reverse: true,
                                    beginAtZero: false,
                                    min: 1, // Mindestwert für die y-Achse
                                    max: 100, // Höchstwert für die y-Achse
                                    stepSize: 10 // Schrittgröße für die y-Achse
                                    // callback: function (value, index, values) {
                                    //     return value.toLocaleString()
                                    // }
                                }
                                // ticks: {
                                //     beginAtZero: true
                                // }
                            }
                        ],
                        xAxes: [
                            {
                                scaleLabel: {
                                    display: true,
                                    labelString: "Dates"
                                }
                                // ticks: {
                                //     beginAtZero: true
                                // }
                            }
                        ]
                    },
                    responsive: true,
                    maintainAspectRatio: false
                }
            }
        },
        computed: {
            test_set() {
                return (
                    process.env.VUE_APP_MONITORING_TEST_SET_COMPANY_ID !== this.CryptoJS.AES.decrypt(localStorage.company_id, process.env.VUE_APP_ENCRYPTION_PW).toString(this.CryptoJS.enc.Utf8) &&
                    process.env.VUE_APP_MONITORING_TEST_SET_ID == this.$route.params.id
                )
            },
            positionColors2() {
                return positionColors
            },
            filterFields() {
                let filterFields = [
                    { name: "Keyword", type: "text", key: "keyword" },
                    { name: "Category", type: "text", key: "category_ids" },
                    { name: "Avg Search Volume", type: "number", key: "avg_search_volume" }
                ]
                const avgSearchVolumeIndex = filterFields.findIndex((field) => field.name === "Avg Search Volume")

                // Competitor mit Domain "lutz.de"
                const competitor = this.selectedCompetitors.find((domain) => domain.domain === this.project_domain)

                if (competitor) {
                    // Neues Filterfeld für den Competitor
                    const competitorField = {
                        domain: competitor.domain,
                        name: `Position - ${competitor.domain}`,
                        key: `rankingsMap.${competitor.domain.split(".").join("_")}.position`,
                        type: "number"
                    }

                    // Füge das neue Filterfeld nach "Avg Search Volume" ein
                    filterFields.splice(avgSearchVolumeIndex + 1, 0, competitorField)
                }

                // Hinzufügen der restlichen Competitors
                this.selectedCompetitors.forEach((domain) => {
                    if (domain.domain !== this.project_domain) {
                        const competitorField = {
                            domain: domain.domain,
                            name: `Position - ${domain.domain}`,
                            key: `rankingsMap.${domain.domain.split(".").join("_")}.position`,
                            type: "number"
                        }
                        filterFields.push(competitorField)
                    }
                })
                if (!this.categorized_project) {
                    //
                    filterFields.splice(1, 1)
                }
                if (this.show_intents) {
                    let indexOfSvH = filterFields.findIndex((e) => e.key == "avg_search_volume")
                    Object.keys(this.intent_icons).map((intent) => {
                        let intentheader = {
                            name: "Search Intent - " + intent,
                            key: "search_intent." + intent,
                            type: "number"
                        }
                        filterFields.splice(indexOfSvH + 1, 0, intentheader)
                    })
                }
                return filterFields
            },
            deleteLabel() {
                let str = ""
                let count = this.selectedRows.length
                return str + count + " Keyword(s) permanently?"
            },
            confirmLabel() {
                let str = "Remove catgories of "
                let count = this.selectedRows.filter((x) => x.category_id).length
                return str + count + " Keyword(s)?"
            },
            footerProps() {
                let footerProps = {
                    itemsPerPageOptions: [10, 25, 50, 100],
                    showFirstLastPage: true
                }
                if (this.$store.state.company_status_monitoring_suite && this.$route.params.id == "65cfe666668eb7c840b60e0c") footerProps["page-text"] = "{0}-{1} of 326628"
                return footerProps
            },
            headers() {
                let h = [
                    { text: "Keyword", value: "keyword" },
                    { text: "Google Searches (AVG)", value: "avg_search_volume", align: "end", tooltip: "Shows the average google search volume for a keyword" },

                    // { text: "Category", value: "categories", align: "end", sortable: false },
                    // Add a header for each domain
                    ...this.selectedCompetitors.map((domain) => ({
                        text: domain.domain,
                        value: `ranking_${domain.domain}`,
                        align: "end",
                        tooltip: "Shows the position and the position change in googles search results based on the selected dates."
                    }))
                    // {
                    //     text: "",
                    //     value: "actions",
                    //     sortable: false,
                    //     align: "end",
                    //     width: 50
                    // }
                ]

                console.log(this.project_domain)
                let indexOfDomain = h.findIndex((e) => e.text == this.project_domain)
                console.log("indexOfDomain", indexOfDomain)
                if (indexOfDomain > -1) {
                    let targetIndex = 2
                    let removedItem = h.splice(indexOfDomain, 1)[0]
                    h.splice(targetIndex, 0, removedItem)
                }
                let indexOfSvH = h.findIndex((e) => e.value == "avg_search_volume")
                let intentheader = {
                    text: "Search Intent",
                    value: "keyword_intent",
                    sortable: false,
                    align: "end",
                    tooltip: "The search intent refers to the user’s motivation behind a search query."
                    // width: "250px"
                }
                if (this.show_intents) {
                    h.splice(indexOfSvH + 1, 0, intentheader)
                }
                return h
            }
        },
        methods: {
            getIconText(index) {
                console.log(index)

                console.log(Object.keys(this.intent_icons))
                let keys = Object.keys(this.intent_icons)
                console.log(keys[index])

                return keys[index]
            },
            async removeKeywordsCategory() {
                if (!this.selectedRows.length) return
                this.confirmLoading = true
                console.log(this.selectedRows)
                const url = process.env.VUE_APP_MONITORING_API_URL + "/project/change/category"
                let post_data = {
                    company_id: localStorage.company_id,
                    project_id: this.$route.params.id,
                    keywords: this.selectedRows
                }
                let response_data = await this.$helpers.axiosPost(url, post_data)
                console.log(response_data.data)
                this.fetchData({
                    sortDesc: this.sortDesc,
                    sortBy: this.sortBy,
                    page: this.page,
                    itemsPerPage: this.itemsPerPage
                })
                this.confirmDialog = false
                this.confirmLoading = false
            },
            showCategorySwitchDialog() {
                if (this.selectedRows.length) {
                    this.showSwitchDialog = true
                    return
                }
                let notifidata = { label: "Please select at least one keyword to change the category!", icon: "mdi-alert-circle-outline", color: "#e5a049" }
                this.$emit("notify", notifidata)
            },
            showAllCatsInTable() {
                event.stopPropagation()
                this.showAllCategories = !this.showAllCategories
            },
            openURL(url) {
                window.open(url, "-blank")
            },
            filtersUpdated(filters) {
                console.log(filters)
                this.filters = filters
                this.fetchData({
                    sortDesc: this.sortDesc,
                    sortBy: this.sortBy,
                    page: this.page,
                    itemsPerPage: this.itemsPerPage
                })
            },
            setCategory(item) {
                event.stopPropagation()
                this.$emit("setCategory", item.category_id)
            },
            setCat(item, index) {
                event.stopPropagation()
                this.$emit("setCategory", item.category_id, index)
            },
            catUpdated() {
                this.showSwitchDialog = false
                this.fetchData({
                    sortDesc: this.sortDesc,
                    sortBy: this.sortBy,
                    page: this.page,
                    itemsPerPage: this.itemsPerPage
                })
                this.selectedRows = []
                let notifidata = { label: "Category successfully changed!", icon: "mdi-check-circle-outline", color: "primary" }
                this.$emit("notify", notifidata)
            },
            onRowClick(row) {
                if (row.blur) return
                if (this.isSelecting) return
                console.log(row)
                let entryIndex = this.selectedRows.findIndex((e) => e._id == row._id)
                if (entryIndex === -1) {
                    //select
                    this.selectedRows.push(row)
                } else {
                    //unselect
                    this.selectedRows.splice(entryIndex, 1)
                }
            },
            menuOpen(item, competitor) {
                const isMenuOpen = this.menuOpenState[competitor + item.keyword]
                console.log(isMenuOpen)
                if (isMenuOpen) {
                    console.log("Menu opened:", item, competitor)
                    //set tooltipdata
                    let labels = this.selectedDates[1] == null ? [JSON.parse(JSON.stringify(this.selectedDates[0]))] : JSON.parse(JSON.stringify(this.selectedDates))
                    let datasets = []
                    if (item.rankings) {
                        let ranking = item.rankings.find((e) => e.domain == competitor)
                        if (ranking) {
                            // best ranking
                            let dataset = {
                                label: ranking.url,
                                data: [undefined, ranking.position],
                                borderColor: this.getColoFromIndex(0) || this.getColorFromString(ranking.url),
                                backgroundColor: (this.getColoFromIndex(0) || this.getColorFromString(ranking.url)) + "20",
                                pointBackgroundColor: (this.getColoFromIndex(0) || this.getColorFromString(ranking.url)) + "20",
                                pointStyle: "circle",
                                pointRadius: 6,
                                pointHoverRadius: 10,
                                borderWidth: 2,
                                fill: "start"
                            }
                            datasets.push(dataset)
                            ranking.multiple.forEach((r, index) => {
                                // adding additional rankigns
                                let dataset = {
                                    label: r.url,
                                    data: [undefined, r.position],
                                    borderColor: this.getColoFromIndex(index + 1) || this.getColorFromString(ranking.url),
                                    backgroundColor: (this.getColoFromIndex(index + 1) || this.getColorFromString(ranking.url)) + "20",
                                    pointBackgroundColor: (this.getColoFromIndex(index + 1) || this.getColorFromString(ranking.url)) + "20",
                                    pointStyle: "circle",
                                    pointRadius: 6,
                                    pointHoverRadius: 10,
                                    borderWidth: 2,
                                    fill: "start"
                                }
                                datasets.push(dataset)
                            })
                        }
                    }
                    if (item.rankingsOld) {
                        let rankingOld = item.rankingsOld ? item.rankingsOld.find((e) => e.domain == competitor) : null
                        if (rankingOld) {
                            // find eintrag und setzte ersten data punkt
                            let index = datasets.findIndex((e) => e.label == rankingOld.url)
                            if (index === -1) {
                                let dataset = {
                                    label: rankingOld.url,
                                    data: [rankingOld.position, undefined],
                                    borderColor: this.getColoFromIndex(datasets.length + 1) || this.getColorFromString(rankingOld.url),
                                    backgroundColor: (this.getColoFromIndex(datasets.length + 1) || this.getColorFromString(rankingOld.url)) + "20",
                                    pointBackgroundColor: (this.getColoFromIndex(datasets.length + 1) || this.getColorFromString(rankingOld.url)) + "20",
                                    pointStyle: "circle",
                                    pointRadius: 6,
                                    pointHoverRadius: 10,
                                    borderWidth: 2,
                                    fill: "start"
                                }
                                datasets.push(dataset)
                            } else {
                                datasets[index].data[0] = rankingOld.position
                            }
                            rankingOld.multiple.forEach((r) => {
                                let index = datasets.findIndex((e) => e.label == r.url)
                                // adding additional rankigns
                                if (index === -1) {
                                    let dataset = {
                                        label: r.url,
                                        data: [r.position, undefined],
                                        borderColor: this.getColoFromIndex(datasets.length + 1) || this.getColorFromString(rankingOld.url),
                                        backgroundColor: (this.getColoFromIndex(datasets.length + 1) || this.getColorFromString(rankingOld.url)) + "20",
                                        pointBackgroundColor: (this.getColoFromIndex(datasets.length + 1) || this.getColorFromString(rankingOld.url)) + "20",
                                        pointStyle: "circle",
                                        pointRadius: 6,
                                        pointHoverRadius: 10,
                                        borderWidth: 2,
                                        fill: "start"
                                    }
                                    datasets.push(dataset)
                                } else {
                                    datasets[index].data[0] = r.position
                                }
                            })
                        }
                    }
                    this.tooltipMenuData.labels = labels
                    this.tooltipMenuData.datasets = datasets
                    this.tooltipMenuData.keyword = item.keyword
                    this.tooltipMenuData.domain = competitor
                    this.tooltipMenuData.chartData = {
                        labels: labels,
                        datasets: datasets
                    }
                    this.tooltipMenuData.tableHeaders = [
                        { text: "URL", value: "url" },
                        ...labels.map((l) => {
                            return { text: l.substring(0, 10), value: l, width: "150px" }
                        })
                    ]
                    this.tooltipMenuData.tableRows = this.tooltipMenuData.datasets.map((d) => {
                        let row = { url: d.label }
                        this.tooltipMenuData.labels.forEach((l, index) => {
                            row[l] = d.data[index]
                        })
                        return row
                    })

                    setTimeout(() => {
                        this.renderChart++
                    }, 110)
                } else {
                    console.log("Menu closed:", item, competitor)
                }
            },
            /**
             * fetches Keywords and rankings of 2 dates for given selected Competitors
             * @param {*} project_id
             * @param {*} category_id
             * @param {*} skip
             * @param {*} limit
             * @param {*} sort
             */
            async fetchData(tableOptions) {
                try {
                    this.loading = true
                    console.time("fetch rankings")
                    const [dataForDate1, dataForDate2] = await Promise.all([this.fetchDataForDate(this.selectedDates[0], tableOptions), this.fetchDataForDate(this.selectedDates[1], tableOptions)])
                    console.timeEnd("fetch rankings")
                    if (!dataForDate2.rankings.length) {
                        dataForDate2.rankings = JSON.parse(JSON.stringify(dataForDate1.rankings))
                        dataForDate2.totalCount = JSON.parse(JSON.stringify(dataForDate1.totalCount))
                    }
                    dataForDate1.rankings.map((oldRanking) => {
                        let actualRanking = dataForDate2.rankings.find((r) => r.keyword == oldRanking.keyword)

                        if (actualRanking) {
                            actualRanking.rankingsOld = oldRanking.rankings
                        } else {
                            console.log("couldnt find ranking")
                        }
                    })

                    this.rankings = dataForDate2.rankings.map((ranking) => {
                        if (ranking.search_intent) {
                            this.show_intents = true
                            ranking.sorted_search_intent = this.sortIntent(JSON.parse(JSON.stringify(ranking.search_intent)))
                        }
                        ranking.category_ids.sort()
                        ranking.category_id = ranking.category_ids[ranking.category_ids.length - 1]
                        return ranking
                    })
                    this.count = dataForDate2.totalCount ? dataForDate2.totalCount : 0
                    this.loading = false
                    console.log(this.rankings)
                } catch (error) {
                    console.log(error)
                }
            },
            async fetchDataForDate(date, tableOptions) {
                try {
                    console.log(tableOptions)
                    const domainArray = this.selectedCompetitors.map((c) => c.domain)
                    const url = process.env.VUE_APP_MONITORING_API_URL + "/project/get/frontend/keywords"
                    const sort = this.getSortObject(tableOptions)
                    console.log(tableOptions.sortDesc)
                    const post_data = {
                        company_id: localStorage.company_id,
                        company_status: this.$store.state.company_status_monitoring_suite,
                        project_id: this.$route.params.id,
                        category_id: this.category_id ? this.category_id : null,
                        domains: domainArray,
                        sortDesc: tableOptions.sortDesc[0] ? -1 : 1,
                        date: date, // Use the current date from the loop
                        search: this.search,
                        start: (tableOptions.page - 1) * tableOptions.itemsPerPage,
                        limit: tableOptions.itemsPerPage,
                        sort: sort,
                        filters: this.filters
                    }

                    if (this.sortDomain) {
                        post_data.sortDomain = this.sortDomain
                    }

                    const response_data = await this.$helpers.axiosPost(url, post_data)
                    return response_data.data
                } catch (error) {
                    console.log(error)
                    throw error
                }
            },
            async deleteKeywords() {
                console.log(this.selectedRows)
                if (!this.selectedRows.length) return
                this.deleteLoading = true
                const url = process.env.VUE_APP_MONITORING_API_URL + "/project/delete/keywords"
                const post_data = {
                    company_id: localStorage.company_id,
                    project_id: this.$route.params.id,
                    keywords: this.selectedRows
                }
                const response_data = await this.$helpers.axiosPost(url, post_data)
                this.fetchData({
                    sortDesc: this.sortDesc,
                    sortBy: this.sortBy,
                    page: this.page,
                    itemsPerPage: this.itemsPerPage
                })
                this.deleteDialog = false
                this.deleteLoading = false
                this.selectedRows = []
                console.log(response_data.data)
                let notifidata = { label: "The selected keywords have been deleted successfully!", icon: "mdi-check-circle-outline", color: "primary" }
                this.$emit("notify", notifidata)
            },
            sortIntent(intent) {
                return Object.entries(intent).sort(([, valueA], [, valueB]) => {
                    if (valueA === null && valueB === null) return 0
                    if (valueA === null) return 1
                    if (valueB === null) return -1
                    return valueB - valueA
                })
            },
            // builds sort object for backend data request
            getSortObject(tableOptions) {
                this.sortDomain = null
                let sort = { avg_search_volume: -1 }
                if (Array.isArray(tableOptions.sortBy) && tableOptions.sortBy.length) {
                    if (tableOptions.sortBy[0].startsWith("ranking")) {
                        this.sortDomain = tableOptions.sortBy[0].substring(8)
                    } else {
                        sort[tableOptions.sortBy[0]] = tableOptions.sortDesc[0] ? -1 : 1
                    }
                }
                return sort
            },
            /**
             * returns pos fpr competitor table
             * @param {*} item
             * @param {*} domain
             */
            getRankingPosition(item, domain) {
                const ranking = item.rankings.find((r) => r.domain === domain)
                // console.log(item)
                const oldRanking = item.rankingsOld ? item.rankingsOld.find((r) => r.domain === domain) : null
                const change = oldRanking && ranking ? ranking.position - oldRanking.position : null
                return { position: ranking ? ranking.position : "-", change: change * -1 } // *-1 : 16 --> 20 = +4 sollte aber minuse 4 ergeben daher *-1
            },
            getColor(position) {
                let colorIndex = Math.ceil(position / 10) - 1
                let color = positionColors[colorIndex] ? positionColors[colorIndex] : positionColors[positionColors.length - 1]
                return color
            },
            getColoFromIndex(index) {
                let colors = ["#C2185B", "#7B1FA2", "#1976D2", "#0097A7", "#388E3C", "#AFB42B", "#F57C00", "#5D4037", "#455A64"]
                return colors[index]
            },
            getColorFromString(str) {
                let hash = 0
                for (let i = 0; i < str.length; i++) {
                    hash = str.charCodeAt(i) + ((hash << 5) - hash) + str.length
                }
                const color = Math.floor(Math.abs((Math.sin(hash) * 16777215) % 1) * 16777215).toString(16)
                return "#" + "0".repeat(6 - color.length) + color
            }
        },
        watch: {
            selectedCompetitors: {
                handler() {
                    // Fetch data when the domains prop changes
                    this.fetchData({
                        sortDesc: this.sortDesc,
                        sortBy: this.sortBy,
                        page: this.page,
                        itemsPerPage: this.itemsPerPage
                    })
                },
                immediate: false // Fetch data initially when the component is created
            },
            async exportData() {
                console.log("download triggered")
                console.log(this.rankings)
                console.log(this.selectedDates)
                let fields = this.headers.map((header) => header.text)
                let rows = this.rankings.map((ranking) => {
                    let row = {}
                    this.headers.map((header) => {
                        if (header.value.substring(0, 8) == "ranking_") {
                            let compRanking = ranking.rankings.find((r) => r.domain == header.text)
                            row[header.text] = compRanking ? compRanking.position : 0
                            // row[header.text + " - " + this.selectedDates[0]] = ranking.rankingsOld.find((r) => r.domain == header.text)
                        } else if (header.value == "avg_search_volume") {
                            row[header.text] = parseInt(ranking[header.value])
                        } else {
                            row[header.text] = ranking[header.value]
                        }
                    })
                    return row
                })
                console.log(rows, fields)

                let post_data = {
                    rows: rows,
                    fields: fields
                }
                if (this.selectedDates[1]) {
                    post_data.fileTitle = "Rankings_" + this.selectedDates[1].substring(0, 10)
                } else {
                    post_data.fileTitle = "Rankings_" + this.selectedDates[0].substring(0, 10)
                }

                const url = process.env.VUE_APP_MONITORING_API_URL + "/project/export/csv"
                let response_data = await this.$helpers.axiosPost(url, post_data)
                window.location = process.env.VUE_APP_MONITORING_API_URL + "/" + response_data.data
            },
            selectedDates() {
                this.fetchData({
                    sortDesc: this.sortDesc,
                    sortBy: this.sortBy,
                    page: this.page,
                    itemsPerPage: this.itemsPerPage
                })
            },
            category_id() {
                this.fetchData({
                    sortDesc: this.sortDesc,
                    sortBy: this.sortBy,
                    page: this.page,
                    itemsPerPage: this.itemsPerPage
                })
            },
            search() {
                // Fetch data when the search query changes
                this.page = 1 // Reset the page to 1 when searching
                this.fetchData({
                    sortDesc: this.sortDesc,
                    sortBy: this.sortBy,
                    page: this.page,
                    itemsPerPage: this.itemsPerPage
                })
            }
        }
    }
</script>

<style lang="scss">
    .blur {
        /* Add the blur effect */
        filter: blur(3px);
        -webkit-filter: blur(3px);
    }
    #urlRankingsTable th {
        background-color: whitesmoke;
    }
    .urlLinkbox {
        border-radius: 5px;
        &:hover {
            background-color: grey !important;
        }
    }
    #rankingstable th {
        white-space: nowrap !important;
    }
    .rtd {
        font-size: 0.98rem !important;
        white-space: nowrap;
    }
    #rankingstable .qchip {
        background-color: rgb(245, 245, 245) !important;
        border-width: 1px;
    }
    #monitoringCompTable .qchip {
        background-color: rgb(245, 245, 245) !important;
        border-width: 1px;
    }
    #trendsTable .qchip {
        background-color: rgb(245, 245, 245) !important;
        border-width: 1px;
    }
    .theme--dark #rankingstable .qchip {
        background-color: black !important;
        border-width: 1px;
    }
    .theme--dark #monitoringCompTable .qchip {
        background-color: black !important;
        border-width: 1px;
    }
    .theme--dark #trendsTable .qchip {
        background-color: black !important;
        border-width: 1px;
    }
    .rankingchange {
        color: grey;
        font-size: 12px;
        /* padding-top: 2px; */
    }
    .compranking-wrapper:hover {
        cursor: pointer;
    }
    #rankingstable #rankinglink:hover {
        color: red !important;
    }
    #rankingstable #rankingslink {
        font-weight: 100;
        color: white !important;
    }
    #rankingstable table > tbody > tr:hover {
        cursor: pointer;
    }
    #trendsTable .theme--light.v-data-table tbody tr.v-data-table__selected {
        background: #8dc4b936 !important;
    }
    /* #rankingstable table > tbody > tr > td:nth-child(1),
    #rankingstable table > tbody > tr > th:nth-child(1) {
        background: var(--v-keywordcol-base) !important;
    }
    #rankingstable table > tbody > tr > td:nth-child(2),
    #rankingstable table > tbody > tr > th:nth-child(2) {
        background: var(--v-keywordcol-base) !important;
    } */
    #rankingstable table > tbody > tr > td:nth-child(3),
    #rankingstable table > thead > tr > th:nth-child(3) {
        /* background: #edeff1 !important; */
        border-left: 2px solid #edeff1;
        border-right: 2px solid #edeff1;
    }
    .theme--dark #rankingstable table > tbody > tr > td:nth-child(3),
    .theme--dark #rankingstable table > thead > tr > th:nth-child(3) {
        /* background: #edeff1 !important; */
        border-left: 2px solid #000000;
        border-right: 2px solid #000000;
    }
    #inspire > div.v-menu__content.theme--light.rounded.menuable__content__active {
        border-radius: 10px !important;
    }
    .intent div.v-progress-linear__determinate.primary {
        opacity: 0.9 !important;
    }
</style>
