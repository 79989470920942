<template>
    <div id="contentcontainter" class="standard-mode">
        <!-- LOADER -->
        <div v-if="!project">
            <div style="display: flex">
                <span class="circularprogress mx-auto">
                    <img id="loader" src="../../assets/quaro-q.svg" />
                </span>
            </div>
        </div>

        <!-- SNACKBAR -->
        <v-snackbar top v-model="notification.show" :color="notification.color">
            <v-icon>{{ notification.icon }}</v-icon
            ><span class="font-weight-bold pl-2"> {{ notification.message }} </span>
        </v-snackbar>

        <!-- Persistent  SNACKBAR -->
        <v-snackbar top class="mt-5" rounded timeout="-1" v-model="persistentNotification.show" :color="persistentNotification.color" vertical style="opacity: 0.95">
            <!-- <div class="mb-1">
                <v-icon small>{{ persistentNotification.icon }}</v-icon>
            </div> -->
            <v-btn right style="top: 6px; right: 4px" absolute icon @click="persistentNotification.show = false"><v-icon>mdi-close</v-icon></v-btn>
            <div v-html="persistentNotification.message" class="mr-10"></div>
            <template v-slot:action="{ attrs }" v-if="persistentNotification.type == 'empty-cat' || persistentNotification.type == 'created-cat'">
                <v-btn
                    small
                    style="background-color: rgba(222, 222, 222, 0.395)"
                    class="mr-2 px-2"
                    rounded
                    text
                    v-bind="attrs"
                    @click="
                        showAddKwDialog.show = true
                        showAddKwDialog.category_id = persistentNotification.id
                        persistentNotification.show = false
                    ">
                    <v-icon small class="mr-1">mdi-cloud-upload</v-icon>
                    upload new
                </v-btn>
                <v-btn
                    small
                    style="background-color: rgba(222, 222, 222, 0.395)"
                    rounded
                    text
                    v-bind="attrs"
                    @click="
                        selectedComponent = 'RankingsTable'
                        activeButton = 'Rankings'
                        persistentNotification.show = false
                    ">
                    <v-icon small class="mr-1">mdi-swap-horizontal</v-icon>
                    assign existing</v-btn
                >
            </template>
            <template v-slot:action="{ attrs }" v-else>
                <v-btn style="background-color: rgba(222, 222, 222, 0.395)" rounded text v-bind="attrs" @click="persistentNotification.show = false"> OK </v-btn>
            </template>
        </v-snackbar>

        <EditProjectDialog
            v-if="editProjectDialog.show"
            :editDialog="editProjectDialog"
            @close="editProjectDialog.show = false"
            @saved="
                editProjectDialog.show = false
                fetchData($route.params.id)
            " />
        <AddKeywordsDialog
            v-if="project"
            :show="showAddKwDialog.show"
            @close="showAddKwDialog.show = false"
            @uploaded="
                showAddKwDialog.show = false
                persistentNotification = {
                    show: true,
                    message: '<b>Keywords uploaded successfully!</b></br> All data will be accesssable after the next update. ',
                    icon: 'mdi-check-circle',
                    color: 'primary'
                }
            "
            @notify="createNotification2"
            :category_id="showAddKwDialog.category_id"
            :isCategorized="project.categorized_project"
            :project_title="project.title"
            :project="project"></AddKeywordsDialog>

        <!-- HEAD -->
        <v-slide-y-transition>
            <div v-if="project">
                <span class="mt-10 ml-1" style="font-size: 12px; display: block">
                    <div class="pb-1">
                        <v-chip x-small color="" class="mr-2 px-2" style="text-transform: capitalize">
                            <v-icon x-small class="mr-1">{{ project.device == "mobile" ? "mdi-cellphone" : "mdi-monitor" }}</v-icon>
                            {{ project.device }}
                        </v-chip>
                        <v-chip x-small color="" class="mr-2 px-2" style="text-transform: uppercase">
                            <v-icon x-small class="mr-1">mdi-map-marker</v-icon>
                            {{ project.langloc.location_text }}
                        </v-chip>
                    </div>
                </span>
                <v-row no-gutters style="font-size: 20px" class="mb-1">
                    <v-menu style="border-radius: 25px !important" transition="scale-transition" offset-y offset-x min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn :disabled="$store.state.monitoring_suite_group.id == 2" class="px-2" v-on="on" text>{{ project.title }} <v-icon small class="ml-1">mdi-chevron-down</v-icon></v-btn>
                        </template>
                        <v-list dense rounded>
                            <!-- <v-list-item disabled @click="">
                                <v-icon small class="mr-2">mdi-share</v-icon>
                                <span style="font-size: 12px"> Share Project</span>
                            </v-list-item> -->
                            <v-tooltip top :disabled="!test_set">
                                <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on">
                                        <v-list-item
                                            :disabled="test_set"
                                            @click="
                                                showAddKwDialog.show = true
                                                showAddKwDialog.category_id = category_id
                                            ">
                                            <v-icon small class="mr-2">mdi-cloud-upload</v-icon>
                                            <span style="font-size: 12px"> Add Keywords</span>
                                        </v-list-item>
                                    </span>
                                </template>
                                <span>Disabled on Example-Set</span>
                            </v-tooltip>

                            <v-tooltip top :disabled="!test_set">
                                <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on">
                                        <v-list-item
                                            :disabled="test_set"
                                            v-on="on"
                                            @click="
                                                editProjectDialog.show = true
                                                editProjectDialog.item = project
                                            ">
                                            <v-icon small class="mr-2">mdi-cog</v-icon>
                                            <span style="font-size: 12px">Project Settings</span>
                                        </v-list-item>
                                    </span>
                                </template>
                                <span>Disabled on Example-Set</span>
                            </v-tooltip>
                        </v-list>
                    </v-menu>
                </v-row>
            </div>
        </v-slide-y-transition>

        <!--  CHART  -->

        <v-slide-y-transition>
            <div v-if="project" class="mt-5">
                <v-card class="rounded_card quaroshadow" style="border-top: 4px solid var(--v-primary-base); overflow: hidden">
                    <v-toolbar id="projects-tool-bar" flat :color="$vuetify.theme.dark ? '' : 'blue-grey lighten-5'">
                        <v-toolbar-items>
                            <v-btn
                                v-for="chartBtn in chartBarButtons"
                                :key="chartBtn.name"
                                :class="{ 'active-button': activeChartButton === chartBtn.name }"
                                @click="activeChartButton = chartBtn.name"
                                text
                                :color="activeChartButton === chartBtn.name ? 'primary' : 'grey darken-2'">
                                {{ chartBtn.label }}
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                    <!-- breadcrumb -->
                    <div class="pl-4 pa-2 breadcrumb" v-if="project.categorized_project">
                        <span><v-chip small color="primary" @click="switchCat(null)">All Categories</v-chip></span>
                        <span v-if="category_id">
                            <span v-for="(cat, index) in category_id.split(';')" :key="cat">
                                <v-icon class="px-1">mdi-chevron-right</v-icon>
                                <v-chip small color="primary" @click="setCategory(category_id, index)"> {{ cat }}</v-chip>
                            </span>
                        </span>
                    </div>
                    <v-card-text>
                        <v-slide-x-transition hide-on-leave>
                            <div v-if="activeChartButton == 'Visibility'">
                                <VisibLineChart :selectedCompetitors="selectedCompetitors" :category_id="category_id"></VisibLineChart>
                            </div>
                        </v-slide-x-transition>
                        <v-slide-x-transition hide-on-leave>
                            <div v-show="activeChartButton == 'Searches'">
                                <SearchesLineChart :show="activeChartButton == 'Searches'" :selectedCompetitors="selectedCompetitors" :category_id="category_id"></SearchesLineChart>
                            </div>
                        </v-slide-x-transition>
                    </v-card-text>
                </v-card>
            </div>
        </v-slide-y-transition>
        <!-- SELECTION -->
        <v-slide-y-transition>
            <div v-if="project" class="mt-5">
                <v-card class="rounded_card quaroshadow">
                    <div class="backsheet"></div>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" md="8">
                                <div class="selectionCol">
                                    <div class="qsublbl">
                                        Competitors
                                        <v-tooltip max-width="250" color="" top>
                                            <template v-slot:activator="{ on }">
                                                <v-icon v-on="on" class="pl-0 mr-auto tooltipicon" x-small color="grey lightn-2">mdi-help-circle-outline</v-icon>
                                            </template>
                                            <div>
                                                <div><b>Choose which competitors to display.</b></div>
                                                <span> By default, the four top competitors in the currently selected category are pre-selected for your convenience.</span>
                                            </div>
                                        </v-tooltip>
                                    </div>
                                    <div style="display: inline-flex">
                                        <v-autocomplete
                                            id="mon_comp_selection"
                                            append-icon="mdi-chevron-down"
                                            class="mt-2"
                                            v-model="selectedCompetitors"
                                            :items="competitors"
                                            rounded
                                            :search-input.sync="compSearchInput"
                                            filled
                                            multiple
                                            persistent-hint
                                            @input="compSearchInput = ''"
                                            hint="Type to search..."
                                            item-text="domain"
                                            return-object>
                                            <template v-slot:selection="{ item, index }">
                                                <span class="mt-2">
                                                    <span v-if="index < 10">
                                                        <!-- <v-chip>{{ item.domain }}</v-chip> -->

                                                        <v-chip class="pl-2 pr-1">
                                                            <!-- <v-icon :size="10" class="mr-1 py-auto">mdi-web</v-icon> -->
                                                            <img
                                                                class="mr-1"
                                                                style="width: 12; border-radius: 5px"
                                                                :src="'https://s2.googleusercontent.com/s2/favicons?domain=' + item.domain + '&sz=12'"
                                                                alt="" />
                                                            {{ item.domain }}
                                                            <v-btn class="ml-1" style="margin-top: 2px" x-small icon @click="selectedCompetitors.splice(index, 1)"
                                                                ><v-icon x-small>mdi-close</v-icon></v-btn
                                                            >
                                                        </v-chip>
                                                    </span>
                                                </span>

                                                <span v-if="index === 10" class="grey--text caption pt-2"> (+{{ selectedCompetitors.length - 10 }} more) </span>
                                            </template>
                                            <template v-slot:item="data">
                                                <v-row
                                                    no-gutters
                                                    :class="disabledCompetitors.has(data.item.domain) ? 'disabledComp' : ''"
                                                    style="border-bottom: 1px solid #eaeaea; border-radius: 10px">
                                                    <v-col class="px-0 flex" style="max-width: 90px">
                                                        <v-icon class="mx-auto" v-if="data.attrs['aria-selected'] == 'true'" color="">mdi-check-circle</v-icon>
                                                    </v-col>
                                                    <v-col class="pl-0 flex">
                                                        <v-list-item two-line>
                                                            <v-list-item-icon class="mr-5">
                                                                <div class="fav_wrapper mt-4">
                                                                    <img class="fav" :src="'https://s2.googleusercontent.com/s2/favicons?domain=' + data.item.domain + '&sz=42'" alt="" />
                                                                </div>
                                                            </v-list-item-icon>
                                                            <v-list-item-content>
                                                                <v-list-item-title>{{ data.item.domain }}</v-list-item-title>
                                                                <v-list-item-subtitle>
                                                                    <v-chip small>Visibility: {{ $helpers.formatNumber(data.item.visibility) }}</v-chip>
                                                                    <v-tooltip max-width="250" top>
                                                                        <template v-slot:activator="{ on }">
                                                                            <v-btn v-on="on" small icon class="ml-1" @click="disableCompetitor(data.item)"
                                                                                ><v-icon :color="disabledCompetitors.has(data.item.domain) ? 'red darken-2' : ''" small
                                                                                    >mdi-eye-off-outline</v-icon
                                                                                ></v-btn
                                                                            >
                                                                        </template>
                                                                        <span>hide competitor </span>
                                                                    </v-tooltip>
                                                                </v-list-item-subtitle>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </v-col>
                                                </v-row>
                                            </template>
                                        </v-autocomplete>
                                        <v-tooltip max-width="250" top>
                                            <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" class="ma-auto ml-2" @click="selectedCompsLocked = !selectedCompsLocked" icon :color="selectedCompsLocked ? 'yellow darken-2' : ''"
                                                    ><v-icon>{{ selectedCompsLocked ? "mdi-lock" : "mdi-lock-open" }}</v-icon></v-btn
                                                >
                                            </template>
                                            <span>Competitor selection wont change while switching categories if locked.</span>
                                        </v-tooltip>
                                    </div>
                                </div>
                            </v-col>
                            <v-col cols="12" md="4">
                                <div class="selectionCol">
                                    <div class="qsublbl">
                                        Time Range
                                        <v-tooltip max-width="250" color="" top>
                                            <template v-slot:activator="{ on }">
                                                <v-icon v-on="on" class="pl-0 mr-auto tooltipicon" x-small color="grey lightn-2">mdi-help-circle-outline</v-icon>
                                            </template>
                                            <div>
                                                <div><b>Select time period:</b></div>
                                                <span> Specify a start and end date. The data within this range will serve as the basis for all visible data and changes.</span>
                                            </div>
                                        </v-tooltip>
                                    </div>
                                    <!-- <v-date-picker v-model="selectedDateRange" :allowed-dates="availableDates" range></v-date-picker> -->
                                    <v-menu
                                        style="border-radius: 25px !important"
                                        v-model="datepickerMenu"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                hide-details
                                                class="mt-2"
                                                v-model="selectedDatesText"
                                                rounded
                                                filled
                                                prepend-inner-icon="mdi-calendar"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker @input="selectedDates.sort()" color="primary" :allowed-dates="allowedDates" show-current="false" range v-model="selectedDates"></v-date-picker>
                                    </v-menu>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </div>
        </v-slide-y-transition>
        <v-slide-y-transition>
            <div v-if="project" class="mt-5">
                <v-card class="rounded_card my-5 quaroshadow" style="border-top: 4px solid var(--v-primary-base); overflow: hidden">
                    <!-- <div class="backsheet"></div> -->
                    <v-toolbar id="projects-tool-bar" flat :color="$vuetify.theme.dark ? '' : 'blue-grey lighten-5'">
                        <v-toolbar-items>
                            <v-btn
                                v-for="btn in toolbarButtons"
                                :key="btn.name"
                                :class="{ 'active-button': activeButton === btn.name }"
                                @click="showComponent(btn.name)"
                                text
                                :color="activeButton === btn.name ? 'primary' : 'grey darken-2'">
                                {{ btn.label }}
                            </v-btn>
                        </v-toolbar-items>
                        <div class="flex-grow-1"></div>

                        <v-text-field
                            v-if="$vuetify.breakpoint.smAndUp"
                            class="pr-5"
                            rounded
                            dense
                            v-model="searchBounce"
                            style="max-width: 400px"
                            filled
                            clearable
                            clear-icon="mdi-close"
                            prepend-inner-icon="mdi-magnify"
                            label="Search..."
                            single-line
                            hide-details></v-text-field>
                        <!-- <v-btn rounded class="ml-auto" color="primary" filled>
                            <v-icon>mdi-cloud-download</v-icon>
                        </v-btn> -->
                        <v-btn rounded @click="initExport" class="" color="primary" filled>
                            <v-icon>mdi-cloud-download</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <!-- breadcrumb -->
                    <div class="pl-4 pa-2 breadcrumb">
                        <div v-if="project.categorized_project">
                            <span><v-chip small color="primary" @click="switchCat(null)">All Categories</v-chip></span>
                            <span v-if="category_id">
                                <span v-for="(cat, index) in category_id.split(';')" :key="cat">
                                    <v-icon class="px-1">mdi-chevron-right</v-icon>
                                    <v-chip small color="primary" @click="setCategory(category_id, index)"> {{ cat }}</v-chip>
                                </span>
                            </span>
                        </div>
                        <div v-else style="height: 24px"></div>
                    </div>
                    <v-slide-x-transition hide-on-leave>
                        <div v-if="selectedComponent == 'RankingsTable'">
                            <RankingsTable
                                :search="search"
                                :selectedCompetitors="selectedCompetitors"
                                :selectedDates="selectedDbDates"
                                :category_id="category_id"
                                :project_domain="project.domain"
                                :exportData="exportRankingsTable"
                                :categorized_project="project.categorized_project"
                                @showAddKwDialog="
                                    showAddKwDialog.show = true
                                    showAddKwDialog.category_id = category_id
                                "
                                @notify="createNotification2"
                                @setCategory="setCatFromTable"></RankingsTable>
                        </div>
                    </v-slide-x-transition>
                    <v-slide-x-transition hide-on-leave>
                        <div v-if="selectedComponent == 'CompetitorsTable'">
                            <CompetitorsTable :search="search" :selectedDates="selectedDbDates" :category_id="category_id" :exportData="exportCompetitorsTable"></CompetitorsTable>
                        </div>
                    </v-slide-x-transition>
                    <v-slide-x-transition hide-on-leave>
                        <div v-if="selectedComponent == 'TrendsTable'">
                            <TrendsTable :search="search" :category_id="category_id" @setCategory="setCatFromTable" :exportData="exportTrendsTable" @notify="createNotification2"></TrendsTable>
                        </div>
                    </v-slide-x-transition>
                    <v-slide-x-transition hide-on-leave>
                        <div v-if="selectedComponent == 'ClusterTable'">
                            <ClusterTable
                                :search="search"
                                :category_id="category_id"
                                :selectedDates="selectedDbDates"
                                :project="project"
                                :project_domain="project.domain"
                                :selectedCompetitors="selectedCompetitors"></ClusterTable>
                        </div>
                    </v-slide-x-transition>
                    <v-slide-x-transition hide-on-leave>
                        <div v-if="selectedComponent == 'CategoriesTable'">
                            <CategoriesTable
                                :search="search"
                                :selectedCompetitors="selectedCompetitors"
                                :selectedDates="selectedDbDates"
                                :category_id="category_id"
                                :exportData="exportCategoriesTable"
                                :project_domain="project.domain"
                                @switchCat="switchCat"
                                @notify="createNotification2"
                                @bottomReached="showComponent('Rankings')"
                                @clearCategoryId="switchCat(null)"
                                @showAddKwDialog="
                                    (item) => {
                                        showAddKwDialog.show = true
                                        showAddKwDialog.category_id = item.category_id
                                    }
                                "></CategoriesTable>
                        </div>
                    </v-slide-x-transition>
                </v-card>
            </div>
        </v-slide-y-transition>
    </div>
</template>

<script>
    import VisibLineChart from "../../components/monitoring/VisibLineChart"
    import SearchesLineChart from "../../components/monitoring/SearchesLineChart"
    import RankingsTable from "../../components/monitoring/RankingsTable"
    import CompetitorsTable from "../../components/monitoring/CompetitorsTable"
    import TrendsTable from "../../components/monitoring/TrendsTable"
    import ClusterTable from "../../components/monitoring/ClusterTable"
    import CategoriesTable from "../../components/monitoring/CategoriesTable"
    import AddKeywordsDialog from "../../components/monitoring/AddKeywordsDialog"
    import EditProjectDialog from "../../components/monitoring/EditProjectDialog.vue"
    import debounce from "lodash/debounce"
    // src/components/monitoring/VisibLineChart.vue
    export default {
        components: {
            VisibLineChart,
            SearchesLineChart,
            RankingsTable,
            CompetitorsTable,
            TrendsTable,
            ClusterTable,
            CategoriesTable,
            AddKeywordsDialog,
            EditProjectDialog
        },
        data() {
            return {
                compSearchInput: "",
                editProjectDialog: { show: false, item: {}, error: null },
                showAddKwDialog: { show: false, category_id: null },
                selectedCompsLocked: false,
                category_id: null,
                search: "",
                searchBounce: "",
                notification: { show: false },
                persistentNotification: { show: false },
                project: null,
                selectedComponent: "RankingsTable",
                activeButton: "Rankings",
                activeChartButton: "Visibility",
                chartBarButtons: [
                    { name: "Visibility", label: "Visibility" },
                    { name: "Searches", label: "Searches" }
                ],
                toolbarButtons: [
                    { name: "Categories", label: "Categories" },
                    { name: "Rankings", label: "Rankings" },
                    { name: "Competitors", label: "Competitors" },
                    { name: "Trends", label: "Trends" }
                ],
                selectedCompetitors: [],
                competitors: [],
                keywords: [],
                headers: [
                    {
                        text: "Project",
                        value: "title", //domain and path
                        sortable: true,
                        width: 400
                    }
                ],
                selectedDates: [],
                selectedDateRange: [],
                availableDates: [],
                datepickerMenu: false,
                disabledCompetitors: new Set(),
                exportRankingsTable: 0,
                exportCompetitorsTable: 0,
                exportTrendsTable: 0,
                exportCategoriesTable: 0
            }
        },
        methods: {
            initExport() {
                console.log(this.selectedComponent)
                this["export" + this.selectedComponent]++
            },
            createNotification2(data) {
                console.log(data)
                if (data.type == "empty-cat" || data.type == "created-cat") {
                    this.persistentNotification = {
                        show: true,
                        message: data.label,
                        icon: data.icon,
                        color: data.color,
                        type: data.type,
                        id: data.id
                    }
                } else {
                    this.notification = this.$helpers.createNotification(data.label, data.icon, data.color)
                }
                // data : "The column title cannot contain '.'! Please try something else! ", "mdi-alert-circle-outline", "#FCBA03"
            },
            disableCompetitor(item) {
                // event.stopPropagation()
                console.log(item)
                if (!item.invisible) {
                    item.invisible = true
                    this.disabledCompetitors.add(item.domain)
                } else {
                    item.invisible = false
                    this.disabledCompetitors.delete(item.domain)
                }

                console.log(this.disabledCompetitors)
            },
            allowedDates(val) {
                return this.availableDates.includes(val)
            },

            async fetchData(project_id, category_id = null) {
                try {
                    //Fetch Project
                    //Fetch Competitors List
                    let data = await Promise.all([this.fetchProject(project_id), this.fetchCompetitors(project_id, category_id)])
                    console.log(data)
                    this.project = data[0]
                    if (this.project.categorized_project) {
                        this.activeButton = "Categories"
                        this.selectedComponent = "CategoriesTable"
                    }
                    this.availableDates = [...new Set(this.project.processed_dates.map((d) => new Date(d).toISOString().substring(0, 10)))]
                    this.selectedDates = this.availableDates.slice(this.availableDates.length - 2, this.availableDates.length)
                    this.competitors = data[1].competitors.map((comp) => {
                        comp.invisible = this.disabledCompetitors.has(comp.domain)
                        return comp
                    })
                    console.log(this.competitors)
                    // this.date_to = this.project.processed_dates[this.project.processed_dates.length - 1]
                    this.selectedCompetitors = this.project.top_competitors
                } catch (error) {
                    //TODO: create notification
                    console.log(error)
                }
            },
            fetchProject(project_id) {
                return new Promise(async (resolve, reject) => {
                    try {
                        const url = process.env.VUE_APP_MONITORING_API_URL + "/project/one"
                        let post_data = {
                            company_id: localStorage.company_id,
                            project_id: project_id
                        }
                        let response_data = await this.$helpers.axiosPost(url, post_data)
                        resolve(response_data.data)
                    } catch (error) {
                        console.log(error)
                        reject(error)
                    }
                })
            },
            fetchCompetitors(project_id, category_id = null) {
                return new Promise(async (resolve, reject) => {
                    try {
                        const url = process.env.VUE_APP_MONITORING_API_URL + "/project/get/competitors/select"
                        let post_data = {
                            company_id: localStorage.company_id,
                            project_id: project_id,
                            query: { project_id: project_id, category_id: category_id },
                            fields: { visibility: { $slice: 1 }, domain: 1 },
                            sort: { "visibility.value": -1 }
                        }
                        let response_data = await this.$helpers.axiosPost(url, post_data)
                        resolve(response_data.data)
                    } catch (error) {
                        console.log(error)
                        reject(error)
                    }
                })
            },
            showComponent(componentName) {
                this.activeButton = componentName

                if (componentName === "Rankings") {
                    this.selectedComponent = "RankingsTable"
                } else if (componentName === "Competitors") {
                    this.selectedComponent = "CompetitorsTable"
                } else if (componentName === "Trends") {
                    this.selectedComponent = "TrendsTable"
                } else if (componentName === "Categories") {
                    this.selectedComponent = "CategoriesTable"
                } else if (componentName === "Cluster") {
                    this.selectedComponent = "ClusterTable"
                }
            },
            setCatFromTable(category_id, index = null) {
                console.log(category_id, index)
                if (index === null) {
                    this.switchCat(category_id)
                    return
                }
                this.setCategory(category_id, index)
            },
            /**
             * sets category by index
             * @param {*} cat
             * @param {*} catindex
             */
            setCategory(category_id, catindex) {
                const cat_split = category_id.split(";")
                let newcat_split = []
                for (let index = 0; index <= catindex; index++) {
                    newcat_split.push(cat_split[index])
                }
                console.log(newcat_split.join(";"))
                this.switchCat(newcat_split.join(";"))
            },
            async switchCat(category_id, _id) {
                console.log("_id", _id)
                if (_id) {
                    // check if category is new and has not been updated yet.-> return
                    const idTimestampDecimal = parseInt(_id.substring(0, 8), 16)
                    // Konvertieren des gewünschten Datums in ein Date-Objekt
                    const lastUpdatedDate = new Date(this.project.processed_dates[this.project.processed_dates.length - 1])

                    // Vergleichen Sie die Zeitstempel
                    if (idTimestampDecimal < lastUpdatedDate.getTime() / 1000) {
                        console.log("Das Dokument wurde vor dem gewünschten Datum erstellt.")
                    } else if (idTimestampDecimal === lastUpdatedDate.getTime() / 1000) {
                        console.log("Das Dokument wurde genau zu dem gewünschten Datum erstellt.")
                    } else {
                        const currentDate = new Date()
                        let nextUpdateDate

                        // Überprüfen, ob das Intervall 28, 30 oder 31 ist
                        if (this.project.interval == 28 || this.project.interval == 30 || this.project.interval == 31) {
                            if (lastUpdatedDate.getDate() > 28) {
                                // Für Tage größer als 28
                                if (lastUpdatedDate.getMonth() == 10) {
                                    // November (0-indexiert)
                                    nextUpdateDate = new Date(lastUpdatedDate.getFullYear() + 1, 0, 1) // 1. Januar des nächsten Jahres
                                } else if (lastUpdatedDate.getMonth() == 11) {
                                    // Dezember
                                    nextUpdateDate = new Date(lastUpdatedDate.getFullYear() + 1, 1, 1) // 1. Februar des nächsten Jahres
                                } else {
                                    nextUpdateDate = new Date(lastUpdatedDate.getFullYear(), lastUpdatedDate.getMonth() + 2, 1) // 1. des übernächsten Monats
                                }
                            } else {
                                // Für Tage kleiner als 29
                                nextUpdateDate = new Date(lastUpdatedDate.getFullYear(), lastUpdatedDate.getMonth() + 1, lastUpdatedDate.getDate())
                            }
                        } else {
                            // Normale Berechnung für andere Intervalle
                            nextUpdateDate = new Date(lastUpdatedDate)
                            nextUpdateDate.setDate(lastUpdatedDate.getDate() + this.project.interval)
                        }

                        // Berechnen, wie viele Millisekunden bis zur nächsten Aktualisierung verbleiben
                        const timeUntilNextUpdate = nextUpdateDate - currentDate

                        // Umrechnen der Millisekunden in Tage und Stunden
                        const daysUntilUpdate = Math.floor(timeUntilNextUpdate / (24 * 60 * 60 * 1000))
                        const hoursUntilUpdate = Math.floor((timeUntilNextUpdate % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000))

                        // Anzeige der Benachrichtigung
                        this.persistentNotification = {
                            show: true,
                            message: `<h3>This category is new and has not been updated yet!</h3><p>The next update is in ${daysUntilUpdate} days and ${hoursUntilUpdate} hours.</p> `,
                            icon: "mdi-alert",
                            color: "#e5a049"
                        }
                        // this.createNotification2({
                        //     label: `This category is new and has not been updated yet! The next update is in ${daysUntilUpdate} days and ${hoursUntilUpdate} hours.`,
                        //     icon: "mdi-alert",
                        //     color: "warning"
                        // })
                        return
                    }
                }

                let data = await this.fetchCompetitors(this.$route.params.id, category_id)
                this.competitors = data.competitors.map((comp) => {
                    comp.invisible = this.disabledCompetitors.has(comp.domain)
                    return comp
                })
                this.competitors.push({})
                this.competitors.pop()
                if (!this.selectedCompsLocked) {
                    let topcomps = this.competitors.filter((c) => !c.invisible).slice(0, 4)
                    let exists = topcomps.find((c) => c.domain === this.project.domain)
                    if (!exists) {
                        let maincomp = this.competitors.find((c) => c.domain === this.project.domain)
                        if (maincomp) {
                            topcomps[3] = maincomp
                        }
                    }
                    this.selectedCompetitors = topcomps
                }
                this.category_id = category_id
            },
            setSearch: debounce(function (val) {
                this.search = val + ""
            }, 400)
        },
        watch: {
            searchBounce(val) {
                this.setSearch(val ? val : "")
            }
        },
        computed: {
            test_set() {
                return (
                    process.env.VUE_APP_MONITORING_TEST_SET_COMPANY_ID !== this.CryptoJS.AES.decrypt(localStorage.company_id, process.env.VUE_APP_ENCRYPTION_PW).toString(this.CryptoJS.enc.Utf8) &&
                    process.env.VUE_APP_MONITORING_TEST_SET_ID == this.project._id
                )
            },
            selectedDatesText() {
                return this.selectedDates.join(" ~ ")
            },
            selectedDbDates() {
                let datehelper = this.project.processed_dates.map((d) => new Date(d).toISOString().substring(0, 10))
                let firstindex = datehelper.indexOf(this.selectedDates[0])
                let secondindex = datehelper.indexOf(this.selectedDates[1])
                return [this.project.processed_dates[firstindex], this.project.processed_dates[secondindex]]
            }
        },
        mounted() {
            if (
                [
                    "6616c4cd7a30a4ecfb610a3a",
                    "6783f0d078d227b22fd78a7c",
                    "670e7a581e357b6bdb853e09",
                    "67276c627c13ea96e05407c5",
                    "6727750265d079a3d3ebdcc9",
                    "67277779d05baae30bae2422",
                    "67277de27c13ea96e067b730",
                    "67277fb16a7b3f79f8ba1aa6",
                    "66c62acd912be079f6a2e12c"
                ].includes(this.$route.params.id)
            ) {
                this.toolbarButtons.push({ name: "Cluster", label: "Cluster" })
            }
            this.fetchData(this.$route.params.id)
        }
    }
</script>
<style scoped>
    .circularprogress {
        margin-top: 20vh;
    }

    .breadcrumb {
        /* background-color: white; */
        white-space: nowrap;
        border-bottom: 4px solid #edeff1;
    }
    .theme--dark .breadcrumb {
        border-bottom: 4px solid black;
    }
</style>
<style>
    .v-autocomplete__content.v-menu__content {
        border-radius: 25px !important;
    }
    .active-button {
        /* border-radius: 10px !important; */

        background-color: rgb(188, 188, 188, 0.5); /* Ändere die gewünschte Farbe */
        color: white !important; /* Ändere die Schriftfarbe */
    }
    .disabledComp {
        text-decoration: line-through;
        opacity: 0.5;
    }
    /* #projects-tool-bar .v-toolbar__content,
    .v-toolbar__extension {
        padding-left: 0px !important;
    } */
</style>
