var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('div',{staticClass:"credit-section mb-4"},[_c('div',{staticClass:"credittype mb-2"},[_vm._v("Research Suite")]),_c('div',{staticClass:"credittype_sub"},[_vm._v("Keyword Requests:")]),_c('div',{staticStyle:{"position":"relative"}},[_c('v-sheet',{staticClass:"d-flex overflow-hidden creditsheet my-1",staticStyle:{"border-radius":"10px"},attrs:{"width":"100%","height":"20px","color":"grey-lighten-3"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-sheet',_vm._g(_vm._b({attrs:{"color":_vm.getColor({ count: _vm.credits.rs.update.count, credits: _vm.credits.rs.research.credits }),"height":"100%","width":(((_vm.credits.rs.update.count / _vm.credits.rs.research.credits) * 100) + "%")}},'v-sheet',attrs,false),on))]}}])},[_c('span',[_vm._v(_vm._s(((_vm.credits.rs.update.count / _vm.credits.rs.research.credits) * 100).toFixed(2))+"% of requests used for saved collections")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-sheet',_vm._g(_vm._b({attrs:{"color":"#009f89bf","height":"100%","width":(((_vm.credits.rs.research.count / _vm.credits.rs.research.credits) * 100) + "%")}},'v-sheet',attrs,false),on))]}}])},[_c('span',[_vm._v(_vm._s(((_vm.credits.rs.research.count / _vm.credits.rs.research.credits) * 100).toFixed(2))+"% of requests used for ideas or suggests")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-sheet',_vm._g(_vm._b({attrs:{"color":"rgb(218 218 218)","height":"100%","width":(((1 - (_vm.credits.rs.update.count / _vm.credits.rs.research.credits + _vm.credits.rs.research.count / _vm.credits.rs.research.credits)) * 100) + "%")}},'v-sheet',attrs,false),on))]}}])},[_c('span',[_vm._v(_vm._s(((1 - (_vm.credits.rs.update.count / _vm.credits.rs.research.credits + _vm.credits.rs.research.count / _vm.credits.rs.research.credits)) * 100).toFixed(2))+"% remaining")])]),_c('div',{staticStyle:{"position":"absolute","left":"50%","top":"2px","transform":"translate(-50%, -8%)","color":"white"}},[_vm._v(" "+_vm._s(Math.ceil(((_vm.credits.rs.update.count + _vm.credits.rs.research.count) / _vm.credits.rs.research.credits) * 100))+"% ")])],1),_vm._v(" Reset date: "+_vm._s(_vm.calculateNextUpdateDate(_vm.credits.rs.reset_date))+" ")],1)]),_c('div',{class:'credit-section mb-4'},[_c('div',{staticClass:"credittype mb-2"},[_vm._v("Content Suite")]),_c('div',{staticClass:"credittype_sub"},[_vm._v("Content Projects:")]),_c('div',{staticClass:"relative my-1"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-progress-linear',_vm._g({staticClass:"progress-bar",attrs:{"value":(_vm.credits.cs.new_projects.count / _vm.credits.cs.new_projects.credits) * 100,"color":_vm.getColor(_vm.credits.cs.new_projects),"height":"20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
return [_c('span',{staticClass:"white--text my-auto"},[_vm._v(_vm._s(_vm.credits.cs.new_projects.count)+" / "+_vm._s(_vm.credits.cs.new_projects.credits))])]}}],null,true)},on))]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.credits.cs.new_projects.count + " of " + _vm.credits.cs.new_projects.credits + " monthly content projects created"))])])],1),_vm._v(" Reset date: "+_vm._s(_vm.calculateNextUpdateDate(_vm.credits.cs.reset_date))+" ")]),_c('div',{staticClass:"credit-section"},[_c('div',{staticClass:"credittype mb-2"},[_vm._v("Monitoring Suite")]),_c('div',{staticClass:"credittype_sub"},[_vm._v("Tracked Keywords:")]),_c('div',{staticClass:"relative my-1"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-progress-linear',_vm._g({staticClass:"progress-bar",attrs:{"value":(_vm.credits.ms.keywords.count / _vm.credits.ms.keywords.credits) * 100,"color":_vm.getColor(_vm.credits.ms.keywords),"height":"20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
return [_c('span',{staticClass:"white--text my-auto"},[_vm._v(_vm._s(_vm.$helpers.formatNumber(_vm.credits.ms.keywords.count))+" / "+_vm._s(_vm.$helpers.formatNumber(_vm.credits.ms.keywords.credits))+" ")])]}}],null,true)},on))]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$helpers.formatNumber(_vm.credits.ms.keywords.count) + " of " + _vm.$helpers.formatNumber(_vm.credits.ms.keywords.credits) + " monthly keywords tracked"))])])],1),_vm._v(" Reset date: "+_vm._s(_vm.calculateNextUpdateDate(_vm.credits.ms.reset_date))+" ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }